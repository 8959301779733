<template>
    <div class="application" :style="{height:screenHeight + 'px'}">
       <div class="application-box">
            <div class="application-text">
                <div class="application-title">
                    <span>{{date.Maintitle}}</span>
                </div>
                <div class="application-subtitle">
                    <span>{{date.Subtitle}}</span>
                </div>
                <div class="application-line"></div>
                <div class="application-content">
                    <div v-html="date.PContent"></div>
                </div>
            </div>
            <div class="application-banner">
                <el-carousel  :interval="5000" :height="Height" trigger="click">
                    <el-carousel-item v-for="(item,index) in date.bannerList" :key="index">
                        <img :src="item.PImage" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
       </div>
    </div>
</template>
<script>
    import { ShowPortalInfo } from "../api/home.js";
    export default {
        name: "application",
        data(){
            return{
                screenHeight: document.documentElement.clientHeight-120,
                Height: ((document.documentElement.clientHeight-120)*0.8)+'px',
                date:{}
            }
        },
        mounted(){
            this.Info()
        },
        methods:{
            Info(){
                let params=`pType=葩钻`
                ShowPortalInfo(params).then(res=>{
                    this.date = res.Data[0]
                    let arr = this.date.PImage.split(',')
                    this.date.bannerList = arr.map(item=>{
                        return {PImage:item}
                    })
                })
            },
        },
    }
</script>
<style scoped lang="scss">
    .application {
        overflow: hidden;
        width: 100%;
        &-box{
            background-image: url('../assets/img/meigui.png');
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: 50%;
            width: 1103px;
            margin: 0 auto;
            height: 100%;
            display: flex;
        }
        &-text{
            width: 50%;
            height: 100%;
        }
        &-banner{
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            .el-carousel{
                margin: 0 auto;
                width: 345px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                }
                /deep/.el-carousel__indicators--horizontal{
                    bottom: 10px;
                    z-index: 89;
                }
                /deep/.el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #E0E0E0;
                    border-radius: 50%;
                    opacity: 1;
                }
                /deep/.el-carousel__indicator--horizontal{
                    padding: 0 !important;
                    margin: 0 6px;
                }
                /deep/.el-carousel__indicator--horizontal.is-active{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
                /deep/.is-active .el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
            }
        }
        &-title{
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #2A2A3E;
            margin-top: 20%;
        }
        &-subtitle{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F4F6C;
            margin-top: 20px;
        }
        &-line{
            margin-top: 50px;
            width: 80px;
            height: 5px;
            background: #0505AE;
            border-radius: 5px;
        }
        &-content{
            margin-top: 50px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            letter-spacing: 2px;
            font-family: '微软雅黑';
            text-indent:2em;
        }
    }
    .application::-webkit-scrollbar { width: 0 !important }
    .application { -ms-overflow-style: none; }
    .application { overflow: -moz-scrollbars-none; }
</style>